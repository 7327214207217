(function ($) {
	"use strict";

	function widthDay() {
		$('.layout-special input.day').each(function () {
			var text = $(this).val() + 1;
			var getWidth = $('<span class="getWidth">' + text + '</span>').insertAfter(this);
			$(this).css({'width': getWidth.outerWidth()}).next('.getWidth').remove();
		});
	}

	$(window).load(function () {
		$('.layout-special input[id^="check_in_date"]').datepicker("option", {
			altField : $('.layout-special input[id^="check_in_date"]').parent().find('.day'),
			altFormat: "dd.",
		});
		$('.layout-special input[id^="check_out_date"]').datepicker("option", {
			altField : $('.layout-special input[id^="check_out_date"]').parent().find('.day'),
			altFormat: "dd.",
		});
		widthDay();
	});

	$(document).ready(function () {

		$('#multidate').daterangepicker({
			autoApply: true,
			parentEl : ".layout-special .hb-form-table",

			locale: {
				format: 'MMMM DD,YYYY'
			}
		});
		$('#multidate').on('apply.daterangepicker', function (ev, picker) {

			$('.layout-special input[id^="check_in_date"]').val(picker.startDate.format('MMMM DD,YYYY'));
			$('#day').val(picker.startDate.format('DD.'));
			$('#month').val(picker.startDate.format('MMM. YYYY'));
			$('.layout-special input[id^="check_out_date"]').val(picker.endDate.format('MMMM DD,YYYY'));
			$('#day2').val(picker.endDate.format('DD.'));
			$('#month2').val(picker.endDate.format('MMM. YYYY'));

			widthDay();
		});

		$('#multidate').on('cancel.daterangepicker', function (ev, picker) {
			$(this).val('');
		});
	});

})(jQuery);